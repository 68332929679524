<template>
    <div style="padding: 12px; background: #EEE; border-top: 1px solid #CCC;" v-if="assets.length > 0">
        <label>Selected assets</label>
        <div style="display: flex; margin-top: 5px; overflow-x: scroll;">
            <div v-for="(asset, index) in assets" :key="`briefing_assets_${index}`"
                class="medialibrary__asset-container" style="background: transparent; padding: 0px;">
                <div class="medialibrary__asset medialibrary__asset--small" :style="!asset.asset_url.toLowerCase().includes('mp4') ? {
                    backgroundSize: 'cover',
                    backgroundImage: `url(${asset.asset_url})`
                } : {}">
                    <span v-if="asset.asset_url.toLowerCase().includes('mp4')">
                        <Video :url="asset.asset_url"></Video>
                        <div class="medialibrary__vid-icon">
                            <i class="fa-regular fa-video"></i>
                        </div>
                    </span>
                    <div class="medialibrary__asset-action">
                        <button class="button button--dark" @click="$emit('remove', asset)">
                            <i class="fa-regular fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Video from '../../studio/components/video.vue'

export default {
    name: 'SelectedAssets',
    components: { Video },
    props: {
        assets: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    emits: ['remove']
}
</script>